import React, {useState} from "react";
import {
    EmailIcon,
    EmailShareButton,
    FacebookIcon,
    FacebookMessengerIcon,
    FacebookMessengerShareButton,
    FacebookShareButton,
    TelegramIcon,
    TelegramShareButton,
    TwitterIcon,
    TwitterShareButton,
    WhatsappIcon,
    WhatsappShareButton
} from "react-share";
import {useParams} from "react-router-dom";

export function ShareContext({testName}: { testName: string }) {
    const parsedName = testName.replace("_", " ");
    const {score} = useParams();
    const [shareUrl] = useState(`${window.location.origin}/${testName}/test`);
    const [shareTitle] = useState(`Hey! look at this awesome ${parsedName} test I just did! \nMy score is: ${score}`);
    return (
        <div id="sharing-context">
            <FacebookShareButton url={shareUrl} quote={shareTitle}>
                <FacebookIcon size={32} round/>
            </FacebookShareButton>
            <FacebookMessengerShareButton
                url={shareUrl}
                appId="521270401588372">
                <FacebookMessengerIcon size={32} round/>
            </FacebookMessengerShareButton>
            <TwitterShareButton
                url={shareUrl}
                title={shareTitle}>
                <TwitterIcon size={32} round/>
            </TwitterShareButton>
            <TelegramShareButton
                url={shareUrl}
                title={shareTitle}>
                <TelegramIcon size={32} round/>
            </TelegramShareButton>
            <WhatsappShareButton
                url={shareUrl}
                title={shareTitle}
                separator=": ">
                <WhatsappIcon size={32} round/>
            </WhatsappShareButton>
            <EmailShareButton
                url={shareUrl}
                subject={shareTitle}
                body="body">
                <EmailIcon size={32} round/>
            </EmailShareButton>
        </div>
    )
}