import {Container} from "reactstrap";
import {Route, Routes, useSearchParams} from "react-router-dom";
import {Test} from "./components/Test/Test";
import {ResultPage} from "./components/Result/ResultPage";
import {initializeApp} from "firebase/app";
import React, {createContext, useEffect, useState} from "react";
import {ThemeSwitcher} from "./components/Test/ThemeSwitcher";
import {DataDescription} from "./components/legal/DataDescription";
import {CookieModal} from "./components/legal/CookieModal";
import {SettingsIcon} from "./components/legal/SettingsIcon";
import {Footer} from "./components/Home/Footer";
import {Imprint} from "./components/legal/Imprint";
import {getBytes, getStorage, ref} from "firebase/storage";
import {Home} from "./components/Home/Home";
import {FirebaseApp} from "@firebase/app";
import {GlobalType} from "./components/Test/GlobalType";


export const FirebaseContext = createContext<FirebaseApp>({} as FirebaseApp);
export const ThemeContext = createContext({
    theme: "dark",
    setTheme: (_: string) => {
    }
});

export const TestsContext = createContext({} as GlobalType["tests"]);

const firebaseConfig = {
    apiKey: process.env.REACT_APP_FB_API_KEY,
    authDomain: process.env.REACT_APP_FB_AUTH_DOMAIN,
    projectId: process.env.REACT_APP_FB_PROJECT_ID,
    storageBucket: process.env.REACT_APP_FB_STORAGE_BUCKET,
    messagingSenderId: process.env.REACT_APP_FB_MESSAGING_SENDER_ID,
    appId: process.env.REACT_APP_FB_APP_ID,
};


function App() {
    const [theme, setTheme] = useState(localStorage.getItem("theme") || "dark");
    const [tests, setTests] = useState({} as GlobalType["tests"]);
    const [loading, setLoading] = useState(true);
    const [searchParams] = useSearchParams();
    const app = initializeApp(firebaseConfig);

    useEffect(() => {
        switch (theme) {
            case "light":
                document.body.classList.add('light-theme');
                localStorage.setItem("theme", "light");
                break;
            case "dark":
            default:
                document.body.classList.remove('light-theme');
                localStorage.setItem("theme", "dark");
                break;
        }
    }, [theme]);



    useEffect(() => {
        void loadData()
    }, []);

    async function loadData() {
        const storage = getStorage(app);
        const buffer = await getBytes(ref(storage, 'tests.json'));
        const decoder = new TextDecoder("utf-8");
        const data = JSON.parse(decoder.decode(buffer));
        setTests(data.tests);
        setLoading(false);
    }

    if (loading) {
        return <div>Loading...</div>
    }

    return (<div className="App">
        <FirebaseContext.Provider value={app}>
            <ThemeContext.Provider value={{theme, setTheme}}>
                <TestsContext.Provider value={tests}>
                    <CookieModal/>
                    <Container>
                        <ThemeSwitcher/>
                        <SettingsIcon open={Boolean(searchParams.get("showSettings"))}/>
                        <Routes>
                            <Route path="/" element={<Home/>}/>
                            <Route path="/:name/test" element={<Test/>}/>
                            <Route path="/:name/result/:score" element={<ResultPage/>}/>
                            <Route path="/cookie-policy" element={<DataDescription/>}/>
                            <Route path="/imprint" element={<Imprint/>}/>
                        </Routes>
                    </Container>
                    <Footer/>
                </TestsContext.Provider>
            </ThemeContext.Provider>
        </FirebaseContext.Provider>
    </div>);
}

export default App;
