import {Button, Input, Modal, ModalBody, ModalFooter, ModalHeader} from "reactstrap";
import {FiSettings} from "react-icons/fi";
import React, {useContext, useEffect, useState} from "react";
import {useNavigate} from "react-router-dom";
import {ThemeContext} from "../../App";

export function SettingsIcon({open}: { open: boolean }) {
    const [showSettings, setShowSettings] = useState(open ?? false);
    const [cookieConsent, setCookieConsent] = useState(false);
    const {theme} = useContext(ThemeContext);
    const navigate = useNavigate();

    useEffect(() => {
        setCookieConsent(document.cookie.includes("cookie-consent=agree"));
    }, [document.cookie]);

    function changeCookieConsent() {
        if (cookieConsent) {
            document.cookie = "cookie-consent=disagree;";
            document.cookie = "expires=Thu, 01 Jan 1970 00:00:00 UTC";
        } else {
            document.cookie = "cookie-consent=agree;"
            document.cookie = `expires=${new Date(Date.now() + 1000 * 60 * 60 * 24 * 365).toUTCString()};`
            navigate("/");
        }
        setCookieConsent(!cookieConsent);
    }

    return (
        <>
            <Button color="secondary" id="settings-icon" onClick={() => setShowSettings(true)}>
                <FiSettings/>
            </Button>
            <Modal isOpen={showSettings} toggle={() => setShowSettings(false)} id={theme === "dark" ? "cookie-modal-dark" : "cookie-modal-light"}>
                <ModalHeader toggle={() => setShowSettings(false)}>Settings</ModalHeader>
                <ModalBody>
                    Personalized ads: <Input type="checkbox" name="personalized-ads" id="personalized-ads"
                                             checked={cookieConsent} onChange={changeCookieConsent}/>
                </ModalBody>
                <ModalFooter>
                    <Button color="primary" onClick={() => setShowSettings(false)}>Close</Button>
                </ModalFooter>
            </Modal>
        </>
    )
}