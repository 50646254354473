import {useContext, useEffect, useState} from "react";
import {ThemeContext} from "../../App";
import {Container, Table} from "reactstrap";
import {useNavigate} from "react-router-dom";
import React from "react";

export function Footer() {
    const {theme} = useContext(ThemeContext);
    const [classNames, setClassNames] = useState<string[]>([]);
    const navigate = useNavigate();
    const imgSrc = theme === "dark" ? "/logo-background192.png" : "/logo192.png";


    useEffect(() => {
        const newClassNames = [];
        newClassNames.push(theme === "dark" ? "footer-dark" : "footer-light");
        if (window.location.pathname === "/") {
            newClassNames.push("footer-home");
        }
        setClassNames(newClassNames);
    }, [window.location.pathname]);
    return (
        <div id="footer" className={classNames.join(" ")}>
            <img src={imgSrc} alt="logo" id="footer-logo" onClick={() => navigate("/")} style={{cursor: "pointer"}}/>
            <Container>
                <Table borderless responsive id="footer-table" className={theme === "dark" ? "footer-table-dark" : ""}>
                    <thead>
                    <tr>
                        <th>Other sites</th>
                        <th>Legal:</th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr>
                        <td>coming soon</td>
                        <td><a href="/cookie-policy">Cookie-policy</a></td>
                    </tr>
                    <tr>
                        <td></td>
                        <td><a href="/imprint">Imprint</a></td>
                    </tr>
                    </tbody>
                </Table>
            </Container>
        </div>
    )
}