import {useNavigate, useParams} from "react-router-dom";
import React, {useContext, useEffect, useState} from "react";
import {ThemeCard} from "../Test/ThemeCard";
import {FirebaseContext, TestsContext} from "../../App";
import {getBytes, getStorage, ref} from "firebase/storage";
import {Button, CardImg} from "reactstrap";
import {ShareContext} from "./ShareContext";
import {testOptions} from "../Test/Test";
import {IoMdArrowBack} from "react-icons/io";
import {TestItem, TestType} from "../Test/TestType";

export function ResultPage() {
    const [result, setResult] = useState({} as TestType["texts"]["results"][0]);
    const [imageUrl, setImageUrl] = useState("");
    const [showShareContext, setShowShareContext] = useState(false);
    const [data, setData] = useState<TestType>({} as TestType);
    const [maxScore, setMaxScore] = useState(0);

    const {name, score} = useParams();

    const navigate = useNavigate();
    const firebase = useContext(FirebaseContext);
    const tests = useContext(TestsContext);

    useEffect(() => {
        void loadData();
    }, []);

    useEffect(() => {
        if (data === undefined || Object.keys(data).length === 0) {
            return;
        }
        const parsedScore = parseInt(score!);
        let newResult = data.texts.results[0];
        data.texts.results.forEach(result => {
            if ((result.start ?? 0) <= parsedScore) {
                newResult = result;
            }
        });
        setResult(newResult);
        if (!data.texts.meme) {
            setImageUrl(newResult.meme as string);
        }
        calculateMaxScore();
    }, [score, data]);

    function calculateMaxScore() {
        console.log(data);
        const newOrdered = [] as TestItem[][];
        data.categoryOrder.forEach(category => {
            newOrdered.push(data.items.filter(question => question.category === category));
        });
        if (data.testOption === testOptions.weighted) {
            let max = 0;
            newOrdered.forEach(category => {
                max += Math.max(...category.map(question => question.weight || 0));
            });
            setMaxScore(max);
        } else if (data.testOption === testOptions.quiz) {
            let correct = 0;
            newOrdered.forEach(category => {
                correct += category.filter(question => question.correct).length;
            });
            setMaxScore(correct);
        } else {
            setMaxScore(data.items.length);
        }
    }

    async function loadData() {
        const storage = getStorage(firebase);
        const testObject = tests.find(test => test.id === name);
        console.log(testObject);
        const buffer = await getBytes(ref(storage, testObject!.location + "/data.json"));
        const decoder = new TextDecoder("utf-8");
        const newData = JSON.parse(decoder.decode(buffer));
        setData(newData);
        if (newData.texts.meme) {
            setImageUrl(newData.texts.meme);
        }
    }

    return (
        <>
            <Button color="primary" onClick={() => navigate("/")}
                    style={{position: "absolute", top: "1.3%", zIndex: 1}}><IoMdArrowBack/></Button>
            <div style={{marginTop: "10px"}}>
                <ThemeCard>
                    <div style={{padding: "2%", alignContent: "center"}}>
                        <h1>{result.title}</h1>
                        <h2>{`${score}/${maxScore}`}</h2>
                        <p>{result.body}</p>
                    </div>
                    <CardImg src={imageUrl} onClick={() => setShowShareContext(!showShareContext)}
                             style={{width: "35%", height: "auto", alignSelf: "center", cursor: "pointer"}}/>

                    <div id="result-group">
                        <Button outline color="success" onClick={() => setShowShareContext(!showShareContext)}>
                            {showShareContext ? "Hide" : "Share"}
                        </Button>
                        <Button outline color="info" onClick={() => navigate(`/${name}/test`)}
                                style={{marginLeft: "5%"}}>
                            Retake Test
                        </Button>
                    </div>
                    {showShareContext ? <ShareContext testName={name as string}/> : null}
                </ThemeCard>
            </div>
        </>
    )
}