import {CardBody, CardImg, Col, Collapse, Input, Row} from "reactstrap";
import {testOptions} from "./Test";
import {BsQuestionCircle} from "react-icons/bs";
import {ThemeCard} from "./ThemeCard";
import React, {useEffect, useState} from "react";
import {TestItem} from "./TestType";

export function Question({
                             question,
                             testType,
                             value,
                             toggleExpand,
                             index,
                             category
                         }: {
                             question: TestItem,
                             testType: string,
                             value: boolean,
                             toggleExpand: boolean,
                             index: number,
                             category: string
                         }
) {
    const [open, setOpen] = useState(false);
    const [hasImage, setHasImage] = useState(question.image !== undefined);

    useEffect(() => {
        setOpen(toggleExpand);
    }, [toggleExpand]);

    return (
        <>
            <hr/>
            <Row>
                <Col xs={1} style={{width: "3%"}}>
                    <Input type={testType === testOptions.weighted ? "radio" : "checkbox"}
                           name={category}
                           checked={value} aria-posinset={index}/>
                </Col>
                <Col align="left">
                    {question.name}
                </Col>
                {question.description === undefined ? null :
                    <Col align="right" onClick={() => setOpen(!open)}>
                        <BsQuestionCircle style={{cursor: "pointer"}}/>
                    </Col>
                }
            </Row>
            <Collapse isOpen={open && question.description !== undefined}
                      style={{marginTop: "10px"}}
                      disabled={!question.description}>
                <ThemeCard>
                    {hasImage ? <CardImg src={question.image} alt={question.name}
                                         style={{width: "50%", alignSelf: "center"}}
                                         onError={() => setHasImage(false)}/> : null}
                    <CardBody>
                        {question.description}
                    </CardBody>
                </ThemeCard>
            </Collapse>
        </>
    )
}