import React, {ReactNode, useContext} from "react";
import {ThemeContext} from "../../App";
import {Card} from "reactstrap";

export function ThemeCard({children}: { children: ReactNode }) {
    const {theme} = useContext(ThemeContext);

    return (
        <Card style={theme === "dark" ? {
            backgroundColor: "var(--color-secondary)",
            borderColor: 'var(--color-quaternary)',
            color: "var(--color-tertiary)"
        } : {backgroundColor: "#fff"}}>
            {children}
        </Card>
    )
}