import {FormGroup} from "reactstrap";
import {testOptions} from "./Test";
import {Question} from "./Question";
import {FormEvent, useState} from "react";
import {TestItem, TestType} from "./TestType";
import React from "react";

export function QuestionCard({
                                 questions,
                                 data,
                                 increaseCount,
                                 toggleExpand
                             }: {
                                 questions: Array<TestItem>,
                                 data: TestType,
                                 increaseCount: (_: number) => void,
                                 toggleExpand: boolean
                             }
) {
    const testType = data.testOption;
    const [values, setValues] = useState({} as Record<string, boolean>);

    function updateCount(e: FormEvent<HTMLInputElement>) {
        const question = questions[(e.target as EventTarget & { ariaPosInSet: number }).ariaPosInSet];
        const addition = (e.target as EventTarget & { checked: boolean }).checked ? 1 : -1;
        const newValues = {...values};
        switch (testType) {
            case testOptions.quiz:
                const factor = question.correct ? 1 : -1;
                console.log(factor);
                increaseCount(addition * factor);
                newValues[question.name] = (e.target as EventTarget & { checked: boolean }).checked;
                break;
            case testOptions.weighted:
                const key = Object.keys(values).find(key => values[key]);
                let result = 0;
                if (key !== undefined) {
                    const oldQuestion = questions.find(question => question.name === key);
                    result = (oldQuestion?.weight || 0) * -1;
                    newValues[key] = false;
                }
                newValues[question.name] = true;
                increaseCount(result + (question.weight || 0));
                break;
            default:
                increaseCount(addition);
                newValues[question.name] = (e.target as EventTarget & { checked: boolean }).checked;
                break;
        }

        setValues(newValues);
    }

    return (
        <FormGroup tag="fieldset" onChange={updateCount}>
            {questions.map((question, index) => {
                return (
                    <Question key={`${index}-${question.name}`} question={question}
                              testType={testType}
                              value={values[question.name] || false}
                              toggleExpand={toggleExpand} index={index}
                              category={questions[0].category}/>
                )
            })}
        </FormGroup>
    )
}