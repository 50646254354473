import {useContext, useEffect, useState} from "react";
import {
    Button,
    Modal,
    ModalBody,
    ModalFooter,
    ModalHeader,
    Toast,
    ToastBody,
    ToastHeader
} from "reactstrap";
import {useLocation, useNavigate} from "react-router-dom";
import {ThemeContext} from "../../App";
import {BiArrowBack} from "react-icons/bi";
import React from "react";

export function CookieModal() {
    const [moreInfo, setMoreInfo] = useState(false);
    const [showAsModal, setShowAsModal] = useState(true);
    const [askedForCookie, setAskedForCookie] = useState(document.cookie.includes("cookie-consent"));
    let usingCookies = false;
    const {theme} = useContext(ThemeContext);
    const navigate = useNavigate();
    const location = useLocation();

    useEffect(() => {
        if (location.pathname === "/cookie-policy") {
            setShowAsModal(false);
        } else {
            setShowAsModal(true);
        }
    }, [location]);

    useEffect(() => {
        if (askedForCookie) {
            usingCookies = document.cookie.includes("cookie-consent=agree");
            if (usingCookies) {
                //googleAds.requestNonPersonalizedAds = 0;
            } else {
                //googleAds.requestNonPersonalizedAds = 1;
            }
        } else {
            //googleAds.requestNonPersonalizedAds = 1;
        }
    }, [askedForCookie, usingCookies]);

    useEffect(() => {
        setAskedForCookie(document.cookie.includes("cookie-consent") && !isCookieExpired());
    }, [location, document.cookie]);

    function isCookieExpired() {
        if (document.cookie.includes("cookie-consent=disagree")) {
            return false;
        }
        const cookieParts = document.cookie.split(";");
        const expiration = cookieParts.find(part => part.includes("expires"));
        if (expiration) {
            const expires = new Date(expiration.split("=")[1]);
            return expires < new Date();
        }
        return true;
    }

    function disagree() {
        document.cookie = "cookie-consent=disagree;";
        document.cookie = "expires=Thu, 01 Jan 1970 00:00:00 UTC;";
        //adsbygoogle.requestNonPersonalizedAds = 1;
        navigate("/");
    }

    function agree() {
        document.cookie = "cookie-consent=agree;"
        document.cookie = `expires=${new Date(Date.now() + 1000 * 60 * 60 * 24 * 365).toUTCString()};`
        //adsbygoogle.requestNonPersonalizedAds = 0;
        navigate("/");
    }

    function redirectToCookiePolicy() {
        navigate("/cookie-policy");
    }

    if (!showAsModal && !askedForCookie) {
        return (
            <Toast className="bottom-cookie-bar"
                   id={`${theme === "dark" ? "cookie-bottom-dark" : "cookie-bottom-light"}`}>
                <ToastHeader>Cookies?</ToastHeader>
                <ToastBody>
                    You can always change your mind later. <br/>
                    <Button color="primary" onClick={agree}
                            style={{marginRight: "20px"}}>Yes</Button>
                    <Button color="primary" onClick={() => setMoreInfo(true)}>No</Button>
                </ToastBody>
            </Toast>
        )
    }

    if (moreInfo) {
        return (
            <Modal isOpen={!askedForCookie}
                   id={theme === "dark" ? "cookie-modal-dark" : "cookie-modal-light"}>
                <ModalHeader> <a href="#" onClick={() => setMoreInfo(false)}><BiArrowBack/>Back </a>
                </ModalHeader>
                <ModalBody>
                    You may see ads that are less relevant to you. These ads use cookies, but not
                    for
                    personalization. <br/>
                    <a href="#" onClick={redirectToCookiePolicy}>Learn more about how we use
                        cookies</a>
                </ModalBody>
                <ModalFooter>
                    <Button color="primary" onClick={disagree}>Confirm</Button>
                </ModalFooter>
            </Modal>
        )
    }


    return (
        <Modal backdrop={false} isOpen={!askedForCookie}
               id={theme === "dark" ? "cookie-modal-dark" : "cookie-modal-light"}>
            <ModalHeader>Cookies?</ModalHeader>
            <ModalBody>Can we use your data to tailor ads for you? <br/>
                Our partners will collect data and use cookies for ad personalization and
                measurement.
                <a href="#" onClick={redirectToCookiePolicy}>Learn how we and our partners collect
                    and use data</a>
            </ModalBody>
            <ModalFooter>
                <Button color="primary" onClick={agree}>Yes</Button>
                <Button color="primary" onClick={() => setMoreInfo(true)}>No</Button>
            </ModalFooter>
        </Modal>
    )

}