import {ThemeContext} from "../../App";
import React, {useContext} from "react";
import {MdOutlineDarkMode, MdOutlineLightMode} from "react-icons/md";
import {Button} from "reactstrap";

export function ThemeSwitcher() {
    const {theme, setTheme} = useContext(ThemeContext);

    function toggleTheme() {
        setTheme(theme === "dark" ? "light" : "dark");
    }

    return (
        <div>
            <Button id="theme-button" color={theme === "light" ? "secondary" : "warning"} onClick={toggleTheme} outline>
                {
                    theme === "light" ? <MdOutlineDarkMode/> : <MdOutlineLightMode/>
                }
            </Button>
        </div>
    )
}