import {Table} from "reactstrap";
import {ThemeContext} from "../../App";
import {useContext, useEffect, useState} from "react";
import {GlobalType} from "../Test/GlobalType";
import React from "react";

export function TestOverview({tests}: {tests: GlobalType}) {
    const [categories, setCategories] = useState<string[]>([]);
    const [orderedTests, setOrderedTests] = useState<OrderedTestsType>({});

    useEffect(() => {
        if (Object.keys(tests).length === 0) {
            return;
        }
        setCategories(tests.categories);
        const newOrderedTests = {} as OrderedTestsType;
        tests.categories.forEach(category => {
            newOrderedTests[category] = tests.tests.filter(test => test.category === category);
        });
        setOrderedTests(newOrderedTests);
    }, [tests]);

    const {theme} = useContext(ThemeContext);
    return (
        <Table borderless responsive className={theme === "dark" ? "footer-table-dark" : ""}>
            <thead>
            <tr>
                {categories.map((category, index) => {
                    return <th key={index}>{category}</th>
                })}
            </tr>
            </thead>
            <tbody>
            {renderTestsForCategory(orderedTests)}
            </tbody>
        </Table>
    )
}

function renderTestsForCategory(orderedTests: OrderedTestsType) {
    let maxCategoryLength = 0;
    Object.values(orderedTests).forEach(tests => {
        maxCategoryLength = Math.max(maxCategoryLength, tests.length);
    });
    const rows = [];
    for (let i = 0; i < maxCategoryLength; i++) {
        rows.push(
            <tr key={i}>
                {Object.values(orderedTests).map((tests, index) => {
                    const test = tests[i];
                    return <td key={index}>{test ? <a href={`/${test.id}/test`}>{test.name}</a> : ""}</td>
                })}
            </tr>
        );
    }
    return rows;
}

type OrderedTestsType = Record<GlobalType["categories"][0], GlobalType["tests"]>;