import React from "react";

export function Imprint() {
    return (
        <div style={{textAlign: "center", fontSize: "2em"}}>
            <h1>Imprint</h1>
            <p>Information in accordance with section 5 TMG:</p>
            <p>Laurin Niemeyer</p>
            <p>Winkstr. 2</p>
            <p>81373 Munich</p>
            <p>E-Mail: itzbubschki@gmail.com</p>
        </div>
    )
}