import React from "react";

export function DataDescription() {
    return (
        <div style={{marginBottom: "20%"}}>
            <h1>Cookie policy for Lifescore</h1>
            <p>
                This document informs Users about the technologies that help this Website to achieve the purposes described below. Such technologies allow the Owner to access and store information (for example by using a Cookie) or use resources (for example by running a script) on a User’s device as they interact with this Website.
                <br/>
                For simplicity, all such technologies are defined as "Trackers" within this document – unless there is a reason to differentiate.
                For example, while Cookies can be used on both web and mobile browsers, it would be inaccurate to talk about Cookies in the context of mobile apps as they are a browser-based Tracker. For this reason, within this document, the term Cookies is only used where it is specifically meant to indicate that particular type of Tracker.
                <br/>
                Some of the purposes for which Trackers are used may also require the User's consent. Whenever consent is given, it can be freely withdrawn at any time following the instructions provided in this document.
                <br/>
                This Website uses Trackers managed directly by the Owner (so-called “first-party” Trackers) and Trackers that enable services provided by a third-party (so-called “third-party” Trackers). Unless otherwise specified within this document, third-party providers may access the Trackers managed by them.
                The validity and expiration periods of Cookies and other similar Trackers may vary depending on the lifetime set by the Owner or the relevant provider. Some of them expire upon termination of the User’s browsing session.
                In addition to what’s specified in the descriptions within each of the categories below, Users may find more precise and updated information regarding lifetime specification as well as any other relevant information – such as the presence of other Trackers - in the linked privacy policies of the respective third-party providers or by contacting the Owner.
            </p>
            <h2>Cookies we use:</h2>
            <p>
                This type of service allows User Data to be utilized for advertising communication purposes. These communications are displayed in the form of banners and other advertisements on this Website, possibly based on User interests.
                <br/>
                This does not mean that all Personal Data are used for this purpose. Information and conditions of use are shown below. <br/>
                Some of the services listed below may use Trackers to identify Users or they may use the behavioral retargeting technique, i.e. displaying ads tailored to the User’s interests and behavior, including those detected outside this Website. For more information, please check the privacy policies of the relevant services.
                <br/>
                Services of this kind usually offer the possibility to opt out of such tracking. In addition to any opt-out feature offered by any of the services below, Users may learn more on how to generally opt out of interest-based advertising within the dedicated section "How to opt-out of interest-based advertising" in this document.
                <br/>
            </p>
            <h3>Google Ads</h3>
            <ul>
                <li>Google's use of advertising cookies enables it and its partners to serve ads to you based on their visit to this site and/or other sites on the Internet.</li>
                <li>You can always opt-out after consenting to the use of cookies either through <a href="https://www.aboutads.info/choices/">www.aboutads.info/choices/</a> or through the <a href="/?showSettings=true">settings</a> on this page</li>
            </ul>
        </div>
    )
}