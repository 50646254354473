import {useContext, useEffect, useState} from "react";
import {FirebaseContext} from "../../App";
import {getBytes, getStorage, ref} from "firebase/storage";
import {TestOverview} from "./TestOverview";
import React from "react";
import {GlobalType} from "../Test/GlobalType";

export function Home() {
    const [tests, setTests] = useState<GlobalType>({} as GlobalType);
    const firebase = useContext(FirebaseContext);
    useEffect(() => {
        void loadTests();
    }, []);

    async function loadTests() {
        const storage = getStorage(firebase);
        const buffer = await getBytes(ref(storage, 'tests.json'));
        const decoder = new TextDecoder("utf-8");
        setTests(JSON.parse(decoder.decode(buffer)));
    }

    return (
        <div id="home-container" style={{textAlign: "center"}}>
            <h1>Welcome to life score</h1>
            <h5>We are currently working on more tests, so please let us know if you have&nbsp;
                <a href="mailto:itzbubschki@gmail.de">suggestions for new ones</a>
            </h5>
            <TestOverview tests={tests}/>
        </div>
    )
}