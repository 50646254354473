import React, {ReactNode, useContext, useEffect, useState} from "react";
import {QuestionCard} from "./QuestionCard";
import {Button, CardBody, CardTitle} from "reactstrap";
import {useNavigate, useParams} from "react-router-dom";
import {FirebaseContext, TestsContext} from "../../App";
import {getBytes, getStorage, ref} from "firebase/storage";
import {ThemeCard} from "./ThemeCard";
import {ExpandAll} from "./ExpandAll";
import {IoMdArrowBack} from "react-icons/io";
import {TestType} from "./TestType";

export const testOptions = {
    score: "Score",
    quiz: "Quiz",
    weighted: "Weighted",
}

export function Test() {
    const {name} = useParams();
    const [count, setCount] = useState(0);
    const [reset, setReset] = useState(false);
    const [expanded, setExpanded] = useState(false);
    const [data, setData] = useState({} as TestType);
    const [loading, setLoading] = useState(true);
    const navigate = useNavigate();

    const firebase = useContext(FirebaseContext);
    const tests = useContext(TestsContext);
    const [testData] = useState(tests.find(test => test.id === name));

    useEffect(() => {
        void loadData()
    }, []);

    async function loadData() {
        const storage = getStorage(firebase);
        const testFile = testData!.location;
        const buffer = await getBytes(ref(storage, `${testFile}/data.json`));
        const decoder = new TextDecoder("utf-8");
        setData(JSON.parse(decoder.decode(buffer)));
        setLoading(false);
    }

    function resetFields() {
        setCount(0);
        setReset(!reset);
    }

    if (loading) {
        return <div>Loading...</div>
    }

    return (
        <div>
            <Button color="primary" onClick={() => navigate("/")}
                    style={{position: "absolute", top: "10px", zIndex: 1}}><IoMdArrowBack/></Button>
            {data.items.filter(i => i.description !== undefined).length > 0 ?
                <ExpandAll expanded={expanded} setExpanded={setExpanded}/> : null}
            <div style={{marginTop: "10px"}}>
                <ThemeCard>
                    <CardTitle align="center">
                        <h1>{testData!.name}</h1>
                        {data.texts.description} <br/>
                        If you have any suggestions for things that should be added to this
                        test&nbsp;
                        <a href="mailto:itzbubschki@gmail.de">feel free to let us know</a> :)
                    </CardTitle>
                    <CardBody>
                        {renderQuestions(data, reset, setCount, count, expanded)}
                    </CardBody>
                </ThemeCard>
                <div style={{marginTop: "5%", alignSelf: "center"}}>
                    <Button color="success" onClick={() => navigate(`/${name}/result/${count}`)}
                            style={{marginRight: "30px"}}>Evaluate</Button>
                    <Button color="danger" onClick={resetFields}>Reset</Button>
                </div>
            </div>
        </div>


    )
}

function renderQuestions(data: TestType, reset: boolean, setCount: (_: number) => void, count: number, expanded: boolean) {
    const result = [] as ReactNode[];
    data.categoryOrder.forEach((category, i) => {
        const categoryQuestions = data.items.filter(i => i.category === category);
        if (categoryQuestions.length > 0) {
            if (result.length > 0) {
                result.push(<hr key={`hr-${i}`}/>);
            }
            result.push(<h3 className="category-title" key={category}>{category}</h3>);
            result.push(<QuestionCard questions={categoryQuestions}
                                      increaseCount={(addition: number) => setCount(count + addition)}
                                      data={data} toggleExpand={expanded} key={i}/>);
        }
    });
    return (result)
}

