import {Button} from "reactstrap";
import {BsArrowsCollapse, BsArrowsExpand} from "react-icons/bs";
import React from "react";

export function ExpandAll({expanded, setExpanded}: { expanded: boolean, setExpanded: (_: boolean) => void }) {
    return (
        <div>
            <Button color="primary" id="expand-all-button" onClick={() => setExpanded(!expanded)}>
                {expanded ? <BsArrowsCollapse/> : <BsArrowsExpand/>}
            </Button>
        </div>
    )
}